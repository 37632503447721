.time-picker-dropdown {
    --dropdown-height: 200px;
    --dropdown-header-height: 40px;
    --dropdown-body-height: calc(var(--dropdown-height) - var(--dropdown-header-height));
}

.time-picker-dropdown .dropdown-menu {
    width: 100%;
    height: var(--dropdown-height);
    padding: 0;
}

.time-picker-dropdown .dropdown-menu .dropdown-menu-header {
    height: var(--dropdown-header-height);
    width: 100%;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: auto;
}

.time-picker-dropdown .dropdown-menu-header .btn-dropdown-cancel,
.time-picker-dropdown .dropdown-menu-header .btn-dropdown-save {
    padding: 0;
    height: 100%;
    text-align: center;
    width: 50%;
    min-width: 0;
    color: var(--app-theme-color);
    border-radius: 0;
}

.time-picker-dropdown .dropdown-menu-header .btn-dropdown-cancel {
    border-top-left-radius: 4px;
}

.time-picker-dropdown .dropdown-menu-header .btn-dropdown-save {
    border-top-right-radius: 4px;
}

.time-picker-dropdown .dropdown-menu-header .btn-dropdown-cancel:hover,
.time-picker-dropdown .dropdown-menu-header .btn-dropdown-save:hover {
    background-color: var(--app-theme-25-color);
}

.time-picker-dropdown .dropdown-menu-body {
    height: var(--dropdown-body-height);
    width: 100%;
    display: flex;
    border-top: 1px solid var(--app-theme-25-color);
    background-color: var(--app-theme-25-color);
    gap: 1px;
}

.time-picker-dropdown .dropdown-menu-body div.scroll {
    height: 100%;
    overflow: auto;
    width: 50%;
    flex: 1;
    background: white;
    padding: 4px;
}

.time-picker-dropdown .dropdown-menu-body div.scroll button {
    width: 100%;
    cursor: pointer;
    text-align: center;
    display: block;
    padding: 5px;
    font-size: small;
    box-shadow: none;
    border: none;
    background-color: white;
    border-radius: 4px;
}

.time-picker-dropdown .dropdown-menu-body div.scroll button:hover {
    background-color: var(--app-theme-25-color);
    font-weight: 600;
}

.time-picker-dropdown .dropdown-menu-body div.scroll button.selected {
    background-color: var(--app-theme-50-color);
    font-weight: 600;
}