:root {
  --primary-color: #074191;
  --secondary-color: #f9bac1;
  --secondary-light-color: #f6edee;
  --greybordercolor: #ededed;
  --form-select-indicator-color: #074191;
  --numDays: 5;
  --numHours: 5;
  --timeHeight: 150px;
}

/* .container-fluid {
  min-height: calc(100vh - 178px);
} */

.primary_bg {
  background-color: var(--primary-color);
}

.primary_text {
  color: var(--primary-color);
}
.pointeradd
  {
    pointer-events: none !important;
  }
.timeline {
  display: grid;
  grid-template-rows: repeat(var(--numHours), var(--timeHeight));
}

.days {
  display: grid;
  grid-column: 2;
  gap: 0px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.events {
  display: block;
  grid-template-rows: repeat(var(--numHours), var(--timeHeight));
  background: #ffffff;
  border: 1px solid var(--greybordercolor);
  position: relative;
  min-height: 700px;
}

.title {
  font-weight: 600;
  margin-bottom: 0.25rem;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 0.5rem;
  background: white;
  position: relative;
  text-align: end;
  height: fit-content;
}

.space,
.date {
  height: 60px
}


body {
  font-family: system-ui, sans-serif;
  background: #F2F5FA !important;
}


.corp-fi {
  background: var(--secondary-light-color);
}

.ent-law {
  background: var(--secondary-light-color);
}

.writing {
  background: var(--secondary-light-color);
}

.securities {
  background: var(--secondary-light-color);
}

.date {
  display: flex;
  background: var(--secondary-light-color);
  border: 1px solid var(--greybordercolor);
  flex-wrap: wrap;
}

.date-num {
  font-size: 1.5rem;
  font-weight: 600;
  display: inline;
  text-align: center;
  width: 100%;
}

.date-day {
  display: inline;
  font-size: 1rem;
  font-weight: 100;
  width: 100%;
  text-align: center;
}

.nav-link {
  color: #ffffff;
}

body {
  background: #F2F5FA;
}

.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid var(--greybordercolor);
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  /* float: left; */
  margin-right: .5em;
  margin-top: 3px;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}

.checkbox {
  margin-bottom: 10px;
}

.fa-check:before {
  color: var(--primary-color);
}

.btn-blue {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  padding: 5px 25px !important;
}

.btn-blue:hover {
  color: #ffffff;
}

.info span {
  margin-left: 10px;
}

.image {
  margin-left: 10px;
}

.search-bar .form-group {
  margin-bottom: 0 !important;
}

.card {
  border-radius: 0.5rem;
}

.leftPanel h4 {
  font-weight: 400;
}

.nav-link:hover {
  color: #ffffff;
}

.fc .fc-button-primary {
  background: #F3F6FB !important;
  border: 1px solid var(--greybordercolor) !important;
  color: #000000 !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.btn-blue1 {
  background-color: var(--secondary-light-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  ;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  top: 90%;
  height: 25px;
  font-size: 12px;
  /* text-align: end; */
  width: 100px;
  margin-left: -98px;

}

.room {
  color: var(--primary-color);
  ;
}

.event div {
  margin-bottom: 5px;
  font-size: 12px;
  text-align: left;
}

.title {
  font-size: 12px !important;
  font-weight: 500;
}

.left-arrow {
  background: var(--primary-color);
  ;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid var(--greybordercolor);
  margin-left: 10px;
  margin-right: 15px;
}

.right-arrow {
  background: var(--primary-color);
  ;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid VAR(--greybordercolor);
  margin-left: 20px;

}

.weekly {
  font-size: 18px;
  text-align: center;
  margin-top: 12px;
}

.switch {
  background: var(--secondary-light-color);
  border: 1px solid var(--greybordercolor);
  border-radius: 5px;
  display: flex;
  padding: 2px;
  float: right;
}

.weeks {
  background: #ffffff;
  padding: 10px 30px;
  border-radius: 5px;
}

.day-switch {
  padding: 10px 30px;
}

.modal-dialog {
  max-width: 60%;
  margin: 1.75rem auto;
}

.cancel {
  width: 200px;
  text-align: center;
}

button:focus {
  outline: none;
}

.time {
  color: #b1adad;
}

.attend {
  color: #b1adad;
}

.add-meeting input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: var(--greybordercolor);
}

.add-meeting input:focus {
  border-color: var(--primary-color);
}

.modal-body {
  padding: 3em;
}

.cal {
  position: absolute;
  padding: 10px 10px;
}

.bd-bottom {
  border-bottom: 1px solid #dddddd;
  padding: 0px 0px 40px 0px;
}

.btn-skyblue {
  background-color: var(--secondary-light-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  padding: 5px 30px !important;
  margin-left: 15px !important;
}

label {
  color: #878383;
}

table thead {
  background: #F3F6FB;
  border: 1px solid var(--greybordercolor);
}

thead td p {
  color: var(--primary-color);
}

.cap {
  color: #b1adad;
}

#weeks .left-arrow {
  margin-left: 10px !important;
}

.dropdown-toggle:empty::after {
  display: none;
}

svg[data-toggle="dropdown"] {
  min-width: 100%;
}

.back {
  vertical-align: center;
  border: none !important;
  background-color: transparent;
}

.back:focus {
  outline: none;
  border: none !important;
  background-color: transparent;
}

/*.infobox,
.editroom,
.switchroom,
.addroom {
  position: fixed;
  top: 0;
  height: 100%;
  right: 0;
  width: 500px;
  transition: right 0.6s;
  z-index: 1;
  
}*/

.switchroom {
  width: 600px !important;
}

.addroom {
  overflow-y: auto;
}

.col-form-label {
  padding-top: 0 !important;
}

@media only screen and (max-width: 1024px) {
  .days {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .arrow-panel {
    margin-bottom: 15px;
  }

  .blocks {
    display: table;
    overflow: scroll;
  }

  #calendar #day-labels {
    display: flex;
  }

  #calendar #day-labels .label {
    min-width: 200px;
  }

  .week-wiew #calendar .week .day {
    width: 200px;
    min-width: 200px;
  }

  #calendar {
    overflow: scroll;
  }

  .week-wiew #calendar .week {
    display: flex;
  }

  .left-arrow,
  .right-arrow {
    padding: 20px 20px;
  }

  .calendar-main-div {
    margin-top: 30px;
  }

  .search-bar .form-group {
    margin-bottom: 15px !important;
  }

  #calendar .badge {
    width: 190px !important;
  }

  .week-wiew .left-month {
    padding-top: 170px !important;
  }

  .left-month ul li {
    font-size: 9px !important;
  }

  .card {
    padding: 1rem !important;
  }

  .day {
    min-width: 220px !important;
  }
}

.event .col-md-2 {
  padding: 0;
}

.dropdown-menu {
  /* top: 40% !important; */
}

.btn-link {
  color: var(--primary-color) !important;
}

.daterangepicker td.active {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.active:hover {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.end-date {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.in-range {
  background-color: var(--secondary-light-color) !important;
}

table thead {
  background-color: var(--secondary-light-color) !important;
}

.daterangepicker td.end-date {
  background-color: var(--primary-color) !important;
}

/* .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--secondary-color) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem var(--secondary-color) !important;
} */
.form-control:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary-color) !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

option:hover {
  background-color: var(--primary-color) !important;
}

/*************************************************************************************************************************************************/


.content-area {
  margin-top: -30px;
}

.Dashboard-heading {
  float: left;
  color: #ffffff;
}

.loc ul {
  list-style: none;
}

.loc .active {
  background-color: #E5EDF5;
  padding: 10px;
  color: #000000;
  border-radius: 5px;
}

.loc ul li {
  margin-bottom: 10px;
  color: #666666;
  padding: 0 0 0 10px;
}

.show-more {
  color: #00000061;
}

.nav-item .nav-link {
  color: #00000080;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #004195 !important;
}

.Details {
  background: #E5EDF5;
  border-radius: 5px;
  border: 1px solid #094c991a;
}

.Details-num {
  color: #004195;
  font-size: 32px;
}

.text-color-grey {
  color: #00000065;
}

.h-85 {
  height: 85%;
}

.desk-reserved {
  color: #767676;
  font-size: 14px;
}

.donuts-chart img {
  min-width: 90px;
  max-width: 100%;
}

.map-pin::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #ffffff;
  transform: translate(-50%, calc(100% + 0px));
}

.map-pin {
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
}

.font-12 {
  font-size: 12px;
}

.text-red {
  color: red;
}

.Pdf {
  font-weight: bold;
  background: #094c991a;
  padding: 10px;
  border: 1px solid #094c9936;
  color: #004195;
}

.excel {
  padding: 10px 15px;
  border: 1px solid #094c9936;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.xls {
  font-weight: bold;
  background: #094c991a;
  padding: 10px;
  border: 1px solid #094c9936;
  color: #004195;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.finance {
  background: #FFEFEF;
  border: 1px solid #dc020233;
}

.finance::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #FFEFEF;
  transform: translate(-50%, calc(100% + 0px));
}

.btn-finance {
  background-color: #DC0202;
  border: 1px solid #DC0202;
}

.finance-text {
  color: #DC0202;
}


.Business-Development {
  background: #DEFFE7;
  border: 1px solid #05b23366;
}

.Business-Development::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #DEFFE7;
  transform: translate(-50%, calc(100% + 0px));
}

.btn-Business-Development {
  background-color: #05B233;
  border: 1px solid #05B233;
}

.Business-Development-text {
  color: #05B233;
}


.It {
  background: #FFFBEB;
  border: 1px solid #f3c20066;
}

.It::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #FFFBEB;
  transform: translate(-50%, calc(100% + 0px));
}

.btn-It {
  background-color: #F3C200;
  border: 1px solid #F3C200;
}

.It-text {
  color: #F3C200;
}

.modal-dialog {
  max-width: 65%;
  margin: 1.75rem auto;
}

.green-btn {
  background-color: #05B233;
  border: 1px solid #05B233;
  border-radius: 5px;
  font-size: 12px;
}

.red-btn {
  background-color: #DC0202;
  border: 1px solid #DC0202;
  border-radius: 5px;
  font-size: 12px;
}

.yellow-btn {
  background-color: #E1B300;
  border: 1px solid #E1B300;
  border-radius: 5px;
  font-size: 12px;
}

.chart {
  min-height: 350px;
  padding: 16px;
}

.doughnut-margin {
  margin-top: -25px;
}

.title-top {
  background-color: #fff;
  /*var(--secondary-color);*/
  padding: 8px 16px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.show-modal {
  display: block !important;
  opacity: 1 !important;
  background: #0000008c;
}

.hide-modal {
  display: none;
  opacity: 0;
}

.message {
  font-size: 16px;
  font-weight: 400;
}

#confirmationModel .modal-header {
  background: var(--primary-color);
  color: #ffffff;
}

#confirmationModel .modal-header .close {
  color: #ffffff;
  opacity: 1;
}

.form-heading {
  margin-top: -10px !important;
  position: absolute;
  background: #ffffff;
  width: 200px;
}

.form-control {
  background-clip: inherit !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.swal2-button:focus {
  box-shadow: none !important;
}
.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important
}
optgroup{
  color:white;

}

optgroup option{
  color:black;
}
.disabled {
  pointer-events: none;
  opacity: 1; /* Optionally reduce opacity to visually indicate it's disabled */
}
.navbar-brand{
  padding-right: 180px;
}
.plusminus-btn
{
  width: 30px !important;
  height: 30px !important;
    background: var(--primary-color) !important;
    color: #ffffff !important;
    font-weight: bold;
    outline:none !important;
}
.plusminus-btn:focus
{
  box-shadow:none !important;
}
.plusminus-btn:hover
{
  background: #ffffff !important;
    color: #000000 !important;
    border: 2px solid var(--primary-color) !important;
}
.align-icon
{
  height: 40px;
    display: flex;
    align-items: center;
}
.btn-primary {
  background: var(--primary-color) !important;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
}
.table-header {
  background: var(--primary-color) !important;
  color: #fff;
}
.icon-color
{
  color: var(--primary-color) !important;
  cursor: pointer;
}
#tenant_table tr
{
  cursor: pointer;
}
body{
  height: 90vh;
    /* padding: 0; */
    min-height: 90vh;
}


.infobox {
  position: fixed;
  top: 0;
  height: 100%;
  right: 0;
  width: 350px;
  transition: right 0.6s;
  z-index: 1;
  /* display: none; */
  overflow: auto;
  box-shadow: 0 0 0 10000px rgba(0,0,0,.40) !important;
  pointer-events: visible !important;
}
.infobox .col-form-label:hover
{
color: var(--primary-color);
}
.filter-sidebar input[type="checkbox"]:checked + label
{
  color: var(--primary-color);
}
#close
{
  cursor: pointer;
}
.break-all
{
  word-break: break-all;
}
.infobox h5
{
  font-size: 1.1rem;
    font-weight: 600;
}
.form-group {
  margin-bottom: 0;
}
.divided
{
  height: 40px;
  background: #ffffff;
  width: 2px;
  margin-left: 20px;
}
.navbar-brand
{
  padding-right: 5px;
  margin-top: -8px;
}
.clear svg
{
  margin: -1px 5px;
}