.form-heading {
    margin-top: -2px !important;
    position: absolute;
    background: #ffffff;
    width: 200px;
}

.sec-spacing {
    border: 1px solid #eee;
    padding: 30px 0px;
}

.modal-footer {
    border-top: 0px Im !important;
}

.col-form-label {
    font-weight: 500;
}

.chekbox {
    cursor: pointer;
}

.accordion-work-time {
    --worktime-warning-color: #FAB000;

    .accordion-item {

        .accordion-header {
            .accordion-button:not(.collapsed) {
                background-color: white !important;
            }

            .accordion-button {
                color: var(--primary-color) !important;
                padding: calc(var(--bs-accordion-btn-padding-y) / 1.5) var(--bs-accordion-btn-padding-x);

                .badge-outline-warning {
                    border: 1px solid var(--worktime-warning-color);
                    color: var(--worktime-warning-color);
                    background-color: white;
                    border-radius: 3px;
                    font-size: 10px;
                    font-weight: 500;
                    height: 16px;
                    width: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .accordion-body {
            padding: 0;
        }

        .accordion-collapse {
            .accordion-body {
                .week-day {
                    .worktime-entry {
                        border-bottom: 1px solid var(--bs-accordion-border-color);
                        height: 3rem;

                        .col-6 {
                            padding: 0;

                            .repeat-checkbox {}

                            .start-time,
                            .end-time {
                                border-right: 1px solid var(--bs-accordion-border-color);
                                height: -webkit-fill-available;
                                width: fit-content;
                            }

                            .start-time,
                            .end-time {
                                .time-picker-dropdown {
                                    .form-control {
                                        border: none !important;
                                        background-color: transparent !important;
                                        color: black !important;
                                    }

                                    .form-control:hover {
                                        border: none !important;
                                        background-color: transparent !important;
                                        color: black !important;
                                    }

                                    .form-control:focus {
                                        border: none !important;
                                        background-color: transparent !important;
                                        color: black !important;
                                    }
                                }
                            }
                        }

                        .col-6 {
                            padding: 0;

                            .work-type {
                                border-right: 1px solid var(--bs-accordion-border-color);
                                flex: 1;
                                height: 100%;
                                // display: flex;
                                // align-items: center;
                                // justify-content: center;

                                .dropdown-toggle {
                                    padding: 0;
                                    height: 100%;
                                    width: 100%;
                                    color: #000;

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    max-height: 250px;
                                    z-index: 99999;

                                    .dropdown-item {
                                        height: 3rem;
                                        font-size: 13px;
                                        cursor: pointer;

                                        .item-name {}

                                        .item-icon {
                                            width: 25px;
                                        }
                                    }

                                    .dropdown-item:hover {
                                        background-color: var(--app-theme-25-color);
                                    }

                                    .selected {
                                        background-color: var(--secondaryColor);
                                    }
                                }
                            }

                            .delete-btn {
                                border: none;
                                background: transparent !important;

                                .fa-trash-can {
                                    color: #FF5454;
                                    font-size: 15px;
                                }

                                .fa-trash-can:hover {
                                    color: #FF5454;
                                    font-size: 15px;
                                }
                            }
                        }
                    }

                    .week-day-footer {
                        height: 3rem;

                        .col-6 {
                            padding: 0;

                            .btn-repeat-for-all-days {
                                background-color: transparent !important;
                                color: var(--primary-color) !important;
                            }

                            .btn-repeat-for-all-days:hover {
                                background-color: transparent !important;
                                color: var(--primary-color) !important;
                            }

                            .btn-repeat-for-all-days-disable {
                                background-color: transparent !important;
                                color: black !important;
                                cursor: no-drop;
                            }

                            .add-registration {
                                background-color: transparent !important;
                                color: var(--primary-color) !important;
                            }
                        }
                    }
                }

                .error {
                    border: 1px solid red !important;
                }
            }
        }
    }
}