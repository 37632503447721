.delete-modal {
  i {
    color: var(--app-theme-color);
    font-size: 36px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 650;
    color: var(--app-theme-color);
  }
}

/* =============== React Confirm Alert =============== */
.react-confirm-alert-overlay {
  z-index: 99999;
  background: rgb(49 49 49 / 56%) !important;
}

.react-confirm-alert.web-confirm-alert {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  max-width: calc(100% - 1rem);

  .react-confirm-alert-body {
    padding: 1rem;
    width: auto;
    max-width: 100%;

    i {
      color: var(--app-theme-color);
      font-size: 36px;
    }

    h2 {
      font-weight: 650;
      width: 100%;
      color: var(--app-theme-color);
    }
  }

  .btn.btn-primary {
    background-color: var(--app-theme-color);
  }
}

/* --------------- React Confirm Alert --------------- */